<header class="main-header-area">

    <div class="main-top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-7">
                    <ul class="top-header-information">
                        <li>
                            <span>Call Us:</span> <a href="tel:4234543123678">+4 234-543 123 678</a>
                        </li>
                        <li>
                            <span>Mail Us:</span> <a href="mailto:info@levi.com">info@levi.com</a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-5 col-md-5">
                    <ul class="top-header-social">
                        <li>
                            <a href="#" target="_blank">
                                <i class='bx bxl-facebook'></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class='bx bxl-twitter'></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class='bx bxl-instagram'></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class='bx bxl-linkedin'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar">
        <div class="container-fluid">
            <nav class="navbar navbar-style-three navbar-expand-lg navbar-light">
                <div class="logo">
                    <a routerLink="/home-twelve">
                        <img src="assets/img/logo.png" class="black-logo" alt="Logo">
                    </a>
                </div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="#home" class="nav-link">
                                Home
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#about" class="nav-link">
                                About
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#services" class="nav-link">
                                Our Services
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#team" class="nav-link">
                                Team
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#blog" class="nav-link">
                                Blog
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#contact" class="nav-link">
                                Contact
                            </a>
                        </li>
                    </ul>

                    <div class="others-option">
                        <div class="d-flex align-items-center">
                            <div class="option-item">
                                <a href="#" class="log-in">Log In</a>
                            </div>
                            <div class="option-item">
                                <a href="#" class="sign-up">Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    
</header>

<div id="home" class="confidence-home-area-wrap">
    <div class="container">
        <div class="confidence-home-wrap-content">
            <span class="sub-title">Welcome to Levi</span>
            <h1>Buy A <b>Home</b> By Confidence</h1>
            <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>

            <ul class="confidence-home-btn">
                <li>
                    <a href="#" class="main-default-btn">Apply Now</a>
                </li>
                
                <li>
                    <a href="#" class="main-optional-btn">Mortgage Expert</a>
                </li>
            </ul>
        </div> 
    </div>
</div>

<div id="about" class="about-style-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-full-content">
                    <span class="sub-title">About Us</span>
                    <h3>We Are Fully Dedicated To Support You</h3>
                    <p class="bold">Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo lacinia eget consectetur.</p>

                    <div class="inner-content">
                        <h4>Support Service</h4>
                        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                    </div>
                    <div class="inner-content">
                        <h4>Refinance Guide</h4>
                        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                    </div>
                    <div class="about-btn">
                        <a href="#" class="main-default-btn">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-mortgage-calculator-form">
                    <h2>Mortgage calculator</h2>

                    <form>
                        <div class="form-group">
                            <label>
                                <i class='bx bx-money'></i>
                            </label>
                            <input type="text" class="form-control" placeholder="Purchase price*">
                        </div>
                        <div class="form-group">
                            <label>
                                <i class='bx bx-dollar-circle'></i>
                            </label>
                            <input type="text" class="form-control" placeholder="Estimated monthly*">
                        </div>
                        <div class="form-group">
                            <label>
                                <i class='bx bxs-badge-dollar'></i>
                            </label>
                            <input type="text" class="form-control" placeholder="Down payment*">
                        </div>
                        <div class="form-group">
                            <label>
                                <i class='bx bx-code-block'></i>
                            </label>
                            <input type="text" class="form-control" placeholder="Property ZIP code*">
                        </div>
                        <div class="form-group">
                            <div class="dropdown">
                                <label>
                                    <i class='bx bxs-check-circle'></i>
                                </label>
                                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Dropdown link
                                </a>
                                
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <i class='bx bx-dollar'></i>
                            </label>
                            <input type="text" class="form-control" placeholder="Load Amount*">
                        </div>
                        <div class="form-group">
                            <label>
                                <i class='bx bx-message-square-detail'></i>
                            </label>
                            <input type="email" class="form-control" placeholder="Email address*">
                        </div>
                        <button type="submit" class="main-default-btn">Calculate Payment</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="offer-style-area-with-full-width pt-100 pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="offer-style-section-content">
                    <span class="sub-title">Choose Your Service</span>
                    <h3>Featured Services Fou You</h3>
                    <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus pellentesque in ipsum id orci porta dapibus cras ultricies ligula sed magna dictum porta quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="offer-style-slider owl-carousel owl-theme">
                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-4.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Delivering A Great Customer Experience</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>

                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-5.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Refinancing Your Home No Doubt About Anything</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>

                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-6.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>

                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-4.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Delivering A Great Customer Experience</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>

                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-5.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Refinancing Your Home No Doubt About Anything</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>

                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-6.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>

                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-4.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Delivering A Great Customer Experience</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>

                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-5.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Refinancing Your Home No Doubt About Anything</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>

                    <div class="offer-style-item">
                        <div class="offer-image">
                            <img src="assets/img/more-home/offer/offer-6.jpg" alt="image">
                        </div>
                        <div class="offer-content">
                            <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                            <a href="#">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="process-style-area-without-bg ptb-100">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Steps & Process</span>
            <h2>Go Beyond With Levi</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="process-style-accordion">
                    <div class="accordion" id="ProcessAccordion">
                        <div class="accordion-item">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span>01</span> Appraisal
                            </button>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#ProcessAccordion">
                                <div class="accordion-body">
                                    <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                    <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <span>02</span> Conversation
                            </button>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#ProcessAccordion">
                                <div class="accordion-body">
                                    <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                    <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span>03</span> Application
                            </button>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#ProcessAccordion">
                                <div class="accordion-body">
                                    <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                    <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span>04</span> Documents
                            </button>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#ProcessAccordion">
                                <div class="accordion-body">
                                    <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                    <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span>05</span> Upfront Underwriting
                            </button>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#ProcessAccordion">
                                <div class="accordion-body">
                                    <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                                    <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh. Nulla quis lorem libero malesuada.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="process-style-wrap-image">
                    <img src="assets/img/more-home/process/process-2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fun-fact-style-area-with-main-color pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-fact-style-item">
                    <h3>20</h3>
                    <p>Years Of Experience</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-fact-style-item">
                    <h3>90</h3>
                    <p>Customer Worldwide</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-fact-style-item">
                    <h3>98</h3>
                    <p>Veteran Home Completed</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-fact-style-item">
                    <h3>56</h3>
                    <p>Volunteers Participated</p>
                </div>
            </div>
        </div>
    </div>

    <div class="fun-fact-style-shape-2">
        <img src="assets/img/more-home/fun-fact-shape-2.png" alt="image">
    </div>
</div>

<div class="why-choose-us-area-without-bg pt-100 pb-70">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Choose Your Service</span>
            <h2>Nearly We Have Been Trying To Provide Those</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="why-choose-us-item with-border">
                    <div class="choose-image">
                        <img src="assets/img/more-home/choose/choose-4.png" alt="image">
                    </div>
                    <div class="choose-content">
                        <h3>Floor Plan Financing</h3>
                        <ul class="list">
                            <li><i class='bx bx-check-circle'></i> Dealership to the next level</li>
                            <li><i class='bx bx-check-circle'></i> Financing option for your dealership</li>
                            <li><i class='bx bx-check-circle'></i> Business insight center</li>
                        </ul>
                        <a href="#" class="choose-btn">Get Quote</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="why-choose-us-item with-border">
                    <div class="choose-image">
                        <img src="assets/img/more-home/choose/choose-5.png" alt="image">
                    </div>
                    <div class="choose-content">
                        <h3>Conventional Loans</h3>
                        <ul class="list">
                            <li><i class='bx bx-check-circle'></i> Require a minimum down payment</li>
                            <li><i class='bx bx-check-circle'></i> Appeal to borrowers with a strong credit</li>
                            <li><i class='bx bx-check-circle'></i> Conventional Loans have flexibility.</li>
                        </ul>
                        <a href="#" class="choose-btn">Get Quote</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="why-choose-us-item with-border">
                    <div class="choose-image">
                        <img src="assets/img/more-home/choose/choose-6.png" alt="image">
                    </div>
                    <div class="choose-content">
                        <h3>MMS Mortgage Services</h3>
                        <ul class="list">
                            <li><i class='bx bx-check-circle'></i> Approval process smooth and easy</li>
                            <li><i class='bx bx-check-circle'></i> You can stay on schedule</li>
                            <li><i class='bx bx-check-circle'></i> We can help you overcome obstacles</li>
                        </ul>
                        <a href="#" class="choose-btn">Get Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
<div class="mortgage-quote-area-with-full-width with-main-color">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-lg-6 col-md-6 p-0">
                <div class="mortgage-quote-content">
                    <h3>Get Mortgage Quote</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                    <a href="#" class="quote-btn">Get Mortgage Quote</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
                <div class="mortgage-quote-image"></div>
            </div>
        </div>
    </div>

    <div class="mortgage-quote-shape">
        <img src="assets/img/more-home/mortgage-quote-shape.png" alt="image">
    </div>
</div>

<div id="team" class="staff-area ptb-100">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Our Team</span>
            <h2>Our Staff Profiles</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="assets/img/more-home/staff/staff-5.jpg" alt="image">

                        <ul class="social-link">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="staff-content">
                        <h3>David Driscoll</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="assets/img/more-home/staff/staff-6.jpg" alt="image">

                        <ul class="social-link">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="staff-content">
                        <h3>Andon Wardin</h3>
                        <span>Loan Officer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="assets/img/more-home/staff/staff-7.jpg" alt="image">

                        <ul class="social-link">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="staff-content">
                        <h3>Mark Morrell</h3>
                        <span>Area Managerr</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="assets/img/more-home/staff/staff-8.jpg" alt="image">

                        <ul class="social-link">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="staff-content">
                        <h3>Jubin Indona</h3>
                        <span>Accountant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faq-style-area-with-full-width-wrap bg-transparent pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-style-image-wrap bg-2"></div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="faq-style-accordion">
                    <span class="sub-title">FAQ</span>
                    <h3>Need Help? Read Popular Questions</h3>

                    <div class="accordion" id="FaqAccordion">
                        <div class="accordion-item">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                How Do You Qualify For A Loan?
                            </button>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                A Good Lender Will Clearly Explain Mortgage?
                            </button>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Can You Get A Mortgage Without A Credit Score?
                            </button>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                How Much Home Can You Afford?
                            </button>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area with-bg-main-color pt-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-time'></i>
                    </div>
                    <p>
                        <span>Mon-Fri (8am - 6pm)</span>
                        <span>Sat-Sun (10am - 2pm)</span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bxs-contact'></i>
                    </div>
                    <p>
                        <span>Contact us</span>
                        <span><a href="mailto:support@levi.com">support@levi.com</a></span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <p>
                        <span>(24 hours / 7 days)</span>
                        <span><a href="tel:4234543123678">+4 234-543 123 678</a></span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-world'></i>
                    </div>
                    <p>
                        <span>9675 Grant Ave.</span>
                        <span>Chicago Heights, IL 60411</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="let-contact-form-with-gray-color">
            <div class="main-section-title">
                <span class="sub-title">Let’s Contact</span>
                <h2>Contact With Us</h2>
            </div>

            <form id="contactForm">
                <div class="row m-0">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="William">
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="levi@gmail.com">
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Phone Number</label>
                            <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="+4 234-543 123 678">
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="your subject">
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label>Your Message</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="write your message"></textarea>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="let-contact-btn">
                            <button type="submit" class="main-default-btn">Send Message</button>
                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </form>
            
            <div class="let-contact-shape">
                <img src="assets/img/more-home/let-contact-shape.png" alt="image">
            </div>
        </div>
    </div>

    <div class="information-shape-1">
        <img src="assets/img/more-home/information-shape-1.png" alt="image">
    </div>
    <div class="information-shape-2">
        <img src="assets/img/more-home/information-shape-2.png" alt="image">
    </div>
</div>

<div class="find-own-loan-area-without-color pt-100 pb-70">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Categories Of Loan</span>
            <h2>Find A Own Loan</h2>
        </div>

        <div class="find-own-loan-list-tab">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="find-own-loan-1-tab" data-bs-toggle="tab" href="#find-own-loan-1" role="tab" aria-controls="find-own-loan-1">Purchase</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="find-own-loan-2-tab" data-bs-toggle="tab" href="#find-own-loan-2" role="tab" aria-controls="find-own-loan-2">Homebuyer</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="find-own-loan-3-tab" data-bs-toggle="tab" href="#find-own-loan-3" role="tab" aria-controls="find-own-loan-3">Refinance</a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="find-own-loan-1" role="tabpanel">
                    <div class="find-own-loan-slider owl-carousel owl-theme">
                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-building-house"></i>
                            </div>
                            <div class="loan-content">
                                <h3>A Conventional Home Loan</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Home Loan</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-bulb"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Homeready</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-badge-dollar"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Renovation Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-money"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Down Payment Has Stalled</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Purchase Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-building-house"></i>
                            </div>
                            <div class="loan-content">
                                <h3>A Conventional Home Loan</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Home Loan</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-bulb"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Homeready</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-badge-dollar"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Renovation Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-money"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Down Payment Has Stalled</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Purchase Loans</span>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="find-own-loan-2" role="tabpanel">
                    <div class="find-own-loan-slider owl-carousel owl-theme">
                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-building-house"></i>
                            </div>
                            <div class="loan-content">
                                <h3>A Conventional Home Loan</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Home Loan</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-bulb"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Homeready</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-badge-dollar"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Renovation Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-money"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Down Payment Has Stalled</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Purchase Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-building-house"></i>
                            </div>
                            <div class="loan-content">
                                <h3>A Conventional Home Loan</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Home Loan</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-bulb"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Homeready</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-badge-dollar"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Renovation Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-money"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Down Payment Has Stalled</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Purchase Loans</span>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="find-own-loan-3" role="tabpanel">
                    <div class="find-own-loan-slider owl-carousel owl-theme">
                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-building-house"></i>
                            </div>
                            <div class="loan-content">
                                <h3>A Conventional Home Loan</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Home Loan</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-bulb"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Homeready</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-badge-dollar"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Renovation Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-money"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Down Payment Has Stalled</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Purchase Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-building-house"></i>
                            </div>
                            <div class="loan-content">
                                <h3>A Conventional Home Loan</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Home Loan</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-bulb"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Homeready</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bxs-badge-dollar"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Renovation Loans</span>
                        </div>

                        <div class="find-own-loan-item">
                            <div class="icon">
                                <i class="bx bx-money"></i>
                            </div>
                            <div class="loan-content">
                                <h3>Down Payment Has Stalled</h3>
                                <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum congue leo eget malesuada.</p>
                            </div>
                            <span class="tag">Purchase Loans</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="testimonials-style-area-with-bg bg-style-two ptb-100">
    <div class="container">
        <div class="testimonials-style-content">
            <span class="sub-title">Testimonials</span>
            <h3>People Are Saying About Us.</h3>

            <div class="testimonials-style-slider owl-carousel owl-theme">
                <div class="testimonials-style-card">
                    <div class="info">
                        <i class='bx bxs-quote-alt-left'></i>
                        <h4>Leslie Williamson</h4>
                        <span>Homeowners</span>
                    </div>
                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                    <ul class="star-list">
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                    </ul>
                </div>

                <div class="testimonials-style-card">
                    <div class="info">
                        <i class='bx bxs-quote-alt-left'></i>
                        <h4>Leslie Williamson</h4>
                        <span>Homeowners</span>
                    </div>
                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                    <ul class="star-list">
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                    </ul>
                </div>

                <div class="testimonials-style-card">
                    <div class="info">
                        <i class='bx bxs-quote-alt-left'></i>
                        <h4>Leslie Williamson</h4>
                        <span>Homeowners</span>
                    </div>
                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                    <ul class="star-list">
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                        <li><i class='bx bx-star'></i></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="blog" class="blog-style-area pt-100 pb-100">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">News & Blog</span>
            <h2>Featured News And Insights</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <a href="#"><img src="assets/img/more-home/blog/blog-1.jpg" alt="image"></a>

                        <div class="tag">Mortgage</div>
                    </div>
                    <div class="blog-content">
                        <ul class="post-meta d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="assets/img/more-home/user-1.jpg" class="rounded-circle" alt="image">
                                    <span>Esther Cooper</span>
                                </div>
                            </li>

                            <li>
                                <i class='bx bxs-calendar'></i> December 27, 2022
                            </li>

                            <li>
                                <i class='bx bx-message-dots'></i> No Comments
                            </li>
                        </ul>
                        <h3>
                            <a href="#">Refinancing Your Home No Doubt About Anything</a>
                        </h3>
                        <p>Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor lectus nibh...</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="blog-style-right-card">
                    <div class="card-box">
                        <div class="blog-content">
                            <div class="tag">Privacy</div>
                            <ul class="post-meta">
                                <li>
                                    <i class='bx bxs-calendar'></i> December 27, 2022
                                </li>

                                <li>
                                    <i class='bx bx-message-dots'></i> No Comments
                                </li>
                            </ul>
                            <h3>
                                <a href="#">What Means Your Mortgage And 5 Ways To Improve</a>
                            </h3>
                            <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus...</p>
                        </div>
                    </div>

                    <div class="card-box">
                        <div class="blog-content">
                            <div class="tag">Taechnoloby</div>
                            <ul class="post-meta">
                                <li>
                                    <i class='bx bxs-calendar'></i> December 27, 2022
                                </li>

                                <li>
                                    <i class='bx bx-message-dots'></i> No Comments
                                </li>
                            </ul>
                            <h3>
                                <a href="#">5 Ways To build Yourself By Hard Working</a>
                            </h3>
                            <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="subscribe-style-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="subscribe-style-wrap-content">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <h2>Do You Want to Get Update What’s Upcoming?</h2>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <form class="newsletter-form" data-toggle="validator">
                            <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
    
                            <button class="main-default-btn" type="submit">
                                Subscribe Now
                            </button>
                            <div id="validator-newsletter" class="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="footer-style-area pt-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo-two.png" alt="Logo">
                        </a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="footer-social">
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Quick Links</h3>
                    
                    <ul class="quick-link">
                        <li>
                            <a href="#" target="_blank">About Us</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">What We Offer</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Why Choose Us</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Company</h3>
                    
                    <ul class="quick-link">
                        <li>
                            <a href="#" target="_blank">Blog</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">FAQ</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Loan Calculator</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Mortgage Calculator</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Support</h3>
                    
                    <ul class="quick-link">
                        <li>
                            <a href="#" target="_blank">Contact</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Loan Calculator</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Terms & Conditionsr</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+4498886660000">+449 888 666 0000</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:hello@levi.com">hello@levi.com</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location' ></i>
                            855 road, broklyn street new york 600
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-style-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <p>© Levi is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <ul class="payment-list">
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/visa.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/master-card.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/paypal.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/skrill.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/master-card2.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/visa2.jpg" alt="Method">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>