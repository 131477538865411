<div class="main-navbar">
    <nav class="navbar navbar-style-two navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <div class="logo">
                <a routerLink="/home-nine">
                    <img src="assets/img/logo.png" class="black-logo" alt="Logo">
                </a>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="#home" class="nav-link">
                            Home
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#about" class="nav-link">
                            About
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#services" class="nav-link">
                            Our Services
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#team" class="nav-link">
                            Team
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#blog" class="nav-link">
                            Blog
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#contact" class="nav-link">
                            Contact
                        </a>
                    </li>
                </ul>

                <div class="others-option">
                    <div class="d-flex align-items-center">
                        <div class="option-item">
                            <a href="tel:15140228419" class="call-btn">Call Us: +1 (514) 022-8419</a>
                        </div>
                        <div class="option-item">
                            <a href="#" class="log-in">Log In</a>
                        </div>
                        <div class="option-item">
                            <a href="#" class="sign-up">Sign Up</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<div id="home" class="better-home-area">
    <div class="container-fluid">
        <ul class="social-links">
            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
        </ul>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="better-home-content">
                    <span class="sub-title">Welcome to Levi</span>
                    <h1>Buy And Get Better Home</h1>
                    <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tr inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>

                    <ul class="better-home-btn">
                        <li>
                            <a href="#" class="main-default-btn">Mortgage Expert</a>
                        </li>
                        <li>
                            <a href="#" class="main-optional-btn">About Us</a>
                        </li>
                    </ul>
                </div> 
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="better-home-image">
                    <img src="assets/img/more-home/banner/better-home.png" alt="image">

                    <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-time'></i>
                    </div>
                    <p>
                        <span>Mon-Fri (8am - 6pm)</span>
                        <span>Sat-Sun (10am - 2pm)</span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bxs-contact'></i>
                    </div>
                    <p>
                        <span>Contact us</span>
                        <span><a href="mailto:support@levi.com">support@levi.com</a></span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <p>
                        <span>(24 hours / 7 days)</span>
                        <span><a href="tel:4234543123678">+4 234-543 123 678</a></span>
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="information-card">
                    <div class="icon">
                        <i class='bx bx-world'></i>
                    </div>
                    <p>
                        <span>9675 Grant Ave.</span>
                        <span>Chicago Heights, IL 60411</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="about-style-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-image-wrap">
                    <img src="assets/img/more-home/about/about-2.png" alt="image">

                    <div class="certified-image">
                        <img src="assets/img/more-home/about/certified.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-style-wrap-content">
                    <span class="sub-title">About Our Company</span>
                    <h3>We Are Fully Dedicated To Support You</h3>
                    <p class="bold">Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo lacinia eget consectetur.</p>
                    
                    <div class="about-list-tab">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="about-1-tab" data-bs-toggle="tab" href="#about-1" role="tab" aria-controls="about-1">Our Mission</a>
                            </li>
    
                            <li class="nav-item">
                                <a class="nav-link" id="about-2-tab" data-bs-toggle="tab" href="#about-2" role="tab" aria-controls="about-2">Our Vision </a>
                            </li>
                        </ul>
    
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="about-1" role="tabpanel">
                                <div class="content">
                                    <p>Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame consectetur adipisicing elit.</p>

                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                        <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                        <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                    </ul>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="about-2" role="tabpanel">
                                <div class="content">
                                    <p>Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame consectetur adipisicing elit.</p>

                                    <ul class="list">
                                        <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                        <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                        <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-style-shape-1">
        <img src="assets/img/more-home/about/about-shape.png" alt="image">
    </div>
</div>

<div class="mortgage-quote-area-with-full-width">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-lg-6 col-md-6 p-0">
                <div class="mortgage-quote-content">
                    <h3>Get Mortgage Quote</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                    <a href="#" class="quote-btn">Get Mortgage Quote</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
                <div class="mortgage-quote-image"></div>
            </div>
        </div>
    </div>
</div>

<div class="offer-style-area pt-100 pb-70">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Why Choose Us</span>
            <h2>We Have Offer To You</h2>
        </div>

        <div class="offer-style-slider owl-carousel owl-theme">
            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-1.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Delivering A Great Customer Experience</h3>
                    <a href="#">Read More</a>
                </div>
            </div>

            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-2.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Refinancing Your Home No Doubt About Anything</h3>
                    <a href="#">Read More</a>
                </div>
            </div>

            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-3.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                    <a href="#">Read More</a>
                </div>
            </div>

            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-1.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Delivering A Great Customer Experience</h3>
                    <a href="#">Read More</a>
                </div>
            </div>

            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-2.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Refinancing Your Home No Doubt About Anything</h3>
                    <a href="#">Read More</a>
                </div>
            </div>

            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-3.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                    <a href="#">Read More</a>
                </div>
            </div>

            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-1.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Delivering A Great Customer Experience</h3>
                    <a href="#">Read More</a>
                </div>
            </div>

            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-2.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Refinancing Your Home No Doubt About Anything</h3>
                    <a href="#">Read More</a>
                </div>
            </div>

            <div class="offer-style-card with-black-color">
                <div class="offer-image">
                    <img src="assets/img/more-home/offer/offer-3.jpg" alt="image">
                </div>
                <div class="offer-content">
                    <h3>Consideration Have Been Divided Get Refinance Advisor</h3>
                    <a href="#">Read More</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="beyond-style-area pb-100">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Our Step And Process</span>
            <h2>Go Beyond With Levi</h2>
        </div>

        <div class="beyond-style-list-tab">
            <div id="thumbs" class="owl-carousel owl-theme">
                <div class="tabs-item">
                    <span>1</span>
                </div>
                <div class="tabs-item">
                    <span>2</span>
                </div>
                <div class="tabs-item">
                    <span>3</span>
                </div>
                <div class="tabs-item">
                    <span>4</span>
                </div>
                <div class="tabs-item">
                    <span>1</span>
                </div>
                <div class="tabs-item">
                    <span>2</span>
                </div>
                <div class="tabs-item">
                    <span>3</span>
                </div>
                <div class="tabs-item">
                    <span>4</span>
                </div>
            </div>

            <div id="beyond-style-item" class="owl-carousel owl-theme">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-content">
                            <h3>Appraisal</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-image">
                            <img src="assets/img/more-home/beyond/beyond.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-content">
                            <h3>Appraisal</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-image">
                            <img src="assets/img/more-home/beyond/beyond.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-content">
                            <h3>Appraisal</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-image">
                            <img src="assets/img/more-home/beyond/beyond.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-content">
                            <h3>Appraisal</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-image">
                            <img src="assets/img/more-home/beyond/beyond.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-content">
                            <h3>Appraisal</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-image">
                            <img src="assets/img/more-home/beyond/beyond.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-content">
                            <h3>Appraisal</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-image">
                            <img src="assets/img/more-home/beyond/beyond.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-content">
                            <h3>Appraisal</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-image">
                            <img src="assets/img/more-home/beyond/beyond.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-content">
                            <h3>Appraisal</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <p>pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacini elementum id enim. Donec rutrum congue leo eget malesuada sed porttitor lectus nibh.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="beyond-style-image">
                            <img src="assets/img/more-home/beyond/beyond.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="beyond-style-shape">
        <img src="assets/img/more-home/beyond/beyond-shape.png" alt="image">
    </div>
</div>

<div class="fun-fact-style-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="fun-fact-style-card">
                            <h3>20</h3>
                            <p>Years Of Experience</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="fun-fact-style-card bg-E5F9F4">
                            <h3>90</h3>
                            <p>Customer Worldwide</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="fun-fact-style-card bg-E5F9F4">
                            <h3>56</h3>
                            <p>Volunteers Participated</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="fun-fact-style-card">
                            <h3>98</h3>
                            <p>Veteran Home Completed</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="fun-fact-style-content">
                    <span class="sub-title">Fun Fact</span>
                    <h3>There Is Some Fact That You Know</h3>
                    <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="fun-fact-style-shape">
        <img src="assets/img/more-home/fun-fact-shape.png" alt="image">
    </div>
</div>

<div class="find-own-loan-area pt-100 pb-70">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Categories Of Loan</span>
            <h2>Find A Own Loan</h2>
        </div>

        <div class="find-own-loan-list-tab">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="find-own-loan-1-tab" data-bs-toggle="tab" href="#find-own-loan-1" role="tab" aria-controls="find-own-loan-1">Purchase</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="find-own-loan-2-tab" data-bs-toggle="tab" href="#find-own-loan-2" role="tab" aria-controls="find-own-loan-2">Homebuyer</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="find-own-loan-3-tab" data-bs-toggle="tab" href="#find-own-loan-3" role="tab" aria-controls="find-own-loan-3">Refinance</a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="find-own-loan-1" role="tabpanel">
                    <div class="find-own-loan-slider owl-carousel owl-theme">
                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-1.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Home Loan</span>
                                <h3>A Conventional Home Loan</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-2.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Homeready</span>
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-3.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Renovation Loans</span>
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-4.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Purchase Loans</span>
                                <h3>Down Payment Has Stalled</h3>
                                <p>Wigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-1.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Home Loan</span>
                                <h3>A Conventional Home Loan</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-2.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Homeready</span>
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-3.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Renovation Loans</span>
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-4.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Purchase Loans</span>
                                <h3>Down Payment Has Stalled</h3>
                                <p>Wigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="find-own-loan-2" role="tabpanel">
                    <div class="find-own-loan-slider owl-carousel owl-theme">
                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-1.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Home Loan</span>
                                <h3>A Conventional Home Loan</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-2.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Homeready</span>
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-3.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Renovation Loans</span>
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-4.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Purchase Loans</span>
                                <h3>Down Payment Has Stalled</h3>
                                <p>Wigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-1.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Home Loan</span>
                                <h3>A Conventional Home Loan</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-2.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Homeready</span>
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-3.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Renovation Loans</span>
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-4.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Purchase Loans</span>
                                <h3>Down Payment Has Stalled</h3>
                                <p>Wigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="find-own-loan-3" role="tabpanel">
                    <div class="find-own-loan-slider owl-carousel owl-theme">
                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-1.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Home Loan</span>
                                <h3>A Conventional Home Loan</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-2.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Homeready</span>
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-3.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Renovation Loans</span>
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-4.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Purchase Loans</span>
                                <h3>Down Payment Has Stalled</h3>
                                <p>Wigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-1.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Home Loan</span>
                                <h3>A Conventional Home Loan</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-2.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Homeready</span>
                                <h3>Homeready Fannie Mortgage</h3>
                                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-3.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Renovation Loans</span>
                                <h3>Renovation Loans For Buyers</h3>
                                <p>Migration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>

                        <div class="find-own-loan-card">
                            <div class="loan-image">
                                <img src="assets/img/more-home/find-loan/find-loan-4.png" alt="image">
                            </div>
                            <div class="loan-content">
                                <span class="tag">Purchase Loans</span>
                                <h3>Down Payment Has Stalled</h3>
                                <p>Wigration do amet contur diisci velit suia nonnua</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faq-style-area-with-full-width pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-style-image">
                    <img src="assets/img/more-home/faq.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-style-accordion">
                    <span class="sub-title">FAQ</span>
                    <h3>Need Help? Read Popular Questions</h3>
                    
                    <div class="accordion" id="FaqAccordion">
                        <div class="accordion-item">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                How Do You Qualify For A Loan?
                            </button>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                A Good Lender Will Clearly Explain Mortgage?
                            </button>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Can You Get A Mortgage Without A Credit Score?
                            </button>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                How Much Home Can You Afford?
                            </button>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                What does a mortgage Advisor do?
                            </button>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                <div class="accordion-body">
                                    <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus. Sed porttitor lectus nibh.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="team" class="staff-area pb-70">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">Our Team</span>
            <h2>Our Staff Profiles</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="assets/img/more-home/staff/staff-5.jpg" alt="image">

                        <ul class="social-link">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="staff-content">
                        <h3>David Driscoll</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="assets/img/more-home/staff/staff-6.jpg" alt="image">

                        <ul class="social-link">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="staff-content">
                        <h3>Andon Wardin</h3>
                        <span>Loan Officer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="assets/img/more-home/staff/staff-7.jpg" alt="image">

                        <ul class="social-link">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="staff-content">
                        <h3>Mark Morrell</h3>
                        <span>Area Managerr</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="staff-card without-border without-border-radius">
                    <div class="staff-image">
                        <img src="assets/img/more-home/staff/staff-8.jpg" alt="image">

                        <ul class="social-link">
                            <li>
                                <a href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="staff-content">
                        <h3>Jubin Indona</h3>
                        <span>Accountant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="contact" class="let-contact-area-with-bg-image ptb-100">
    <div class="container">
        <div class="let-contact-form">
            <span class="sub-title">Let’s Contact</span>
            <h3>Contact With Us</h3>

            <form id="contactForm">
                <div class="row m-0">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="William">
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="levi@gmail.com">
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Phone Number</label>
                            <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="+4 234-543 123 678">
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="your subject">
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label>Your Message</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="write your message"></textarea>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="let-contact-btn">
                            <button type="submit" class="main-default-btn">Send Message</button>
                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="testimonials-style-area bg-ffffff ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-5">
                <div class="testimonials-style-image">
                    <img src="assets/img/more-home/testimonials/testimonials-2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-7">
                <div class="testimonials-style-content">
                    <span class="sub-title">Testimonials</span>
                    <h3>People Are Saying About Us.</h3>

                    <div class="testimonials-style-slider owl-carousel owl-theme">
                        <div class="testimonials-style-card">
                            <div class="info">
                                <i class='bx bxs-quote-alt-left'></i>
                                <h4>Leslie Williamson</h4>
                                <span>Homeowners</span>
                            </div>
                            <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                            <ul class="star-list">
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                            </ul>
                        </div>

                        <div class="testimonials-style-card">
                            <div class="info">
                                <i class='bx bxs-quote-alt-left'></i>
                                <h4>Leslie Williamson</h4>
                                <span>Homeowners</span>
                            </div>
                            <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                            <ul class="star-list">
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                            </ul>
                        </div>

                        <div class="testimonials-style-card">
                            <div class="info">
                                <i class='bx bxs-quote-alt-left'></i>
                                <h4>Leslie Williamson</h4>
                                <span>Homeowners</span>
                            </div>
                            <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                            <ul class="star-list">
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                                <li><i class='bx bx-star'></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="overview-style-area">
    <div class="container">
        <div class="overview-style-inner-box">
            <div class="content">
                <h3>Take The First Step Toward The Right <span>Mortgage</span></h3>
            
                <ul class="overview-btn">
                    <li>
                        <a href="#" class="main-default-btn">Career Opportunity</a>
                    </li>
                    <li>
                        <a href="#" class="main-optional-btn">Join Community</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="overview-style-shape-1">
        <img src="assets/img/more-home/overview-shape.png" alt="image">
    </div>
</div>

<div id="blog" class="blog-style-area pt-100 pb-100">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">News & Blog</span>
            <h2>Featured News And Insights</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <a href="#"><img src="assets/img/more-home/blog/blog-1.jpg" alt="image"></a>

                        <div class="tag">Mortgage</div>
                    </div>
                    <div class="blog-content">
                        <ul class="post-meta d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="assets/img/more-home/user-1.jpg" class="rounded-circle" alt="image">
                                    <span>Esther Cooper</span>
                                </div>
                            </li>

                            <li>
                                <i class='bx bxs-calendar'></i> December 27, 2022
                            </li>

                            <li>
                                <i class='bx bx-message-dots'></i> No Comments
                            </li>
                        </ul>
                        <h3>
                            <a href="#">Refinancing Your Home No Doubt About Anything</a>
                        </h3>
                        <p>Quisque velit nisi pretium ut lacinia in elementum id enim pellentesque in ipsum id orci porta dapibus sed porttitor lectus nibh...</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="blog-style-right-card">
                    <div class="card-box">
                        <div class="blog-content">
                            <div class="tag">Privacy</div>
                            <ul class="post-meta">
                                <li>
                                    <i class='bx bxs-calendar'></i> December 27, 2022
                                </li>

                                <li>
                                    <i class='bx bx-message-dots'></i> No Comments
                                </li>
                            </ul>
                            <h3>
                                <a href="#">What Means Your Mortgage And 5 Ways To Improve</a>
                            </h3>
                            <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus...</p>
                        </div>
                    </div>

                    <div class="card-box">
                        <div class="blog-content">
                            <div class="tag">Taechnoloby</div>
                            <ul class="post-meta">
                                <li>
                                    <i class='bx bxs-calendar'></i> December 27, 2022
                                </li>

                                <li>
                                    <i class='bx bx-message-dots'></i> No Comments
                                </li>
                            </ul>
                            <h3>
                                <a href="#">5 Ways To build Yourself By Hard Working</a>
                            </h3>
                            <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Pellentesque in ipsum id orci porta dapibus...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="subscribe-style-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="subscribe-style-wrap-content">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <h2>Do You Want to Get Update What’s Upcoming?</h2>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <form class="newsletter-form" data-toggle="validator">
                            <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
    
                            <button class="main-default-btn" type="submit">
                                Subscribe Now
                            </button>
                            <div id="validator-newsletter" class="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="footer-style-area pt-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo-two.png" alt="Logo">
                        </a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="footer-social">
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Quick Links</h3>
                    
                    <ul class="quick-link">
                        <li>
                            <a href="#" target="_blank">About Us</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">What We Offer</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Why Choose Us</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Company</h3>
                    
                    <ul class="quick-link">
                        <li>
                            <a href="#" target="_blank">Blog</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">FAQ</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Loan Calculator</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Mortgage Calculator</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="single-footer-widget ps-3">
                    <h3>Support</h3>
                    
                    <ul class="quick-link">
                        <li>
                            <a href="#" target="_blank">Contact</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Loan Calculator</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Terms & Conditionsr</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+4498886660000">+449 888 666 0000</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:hello@levi.com">hello@levi.com</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location' ></i>
                            855 road, broklyn street new york 600
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-style-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <p>© Levi is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="copyright-item">
                        <ul class="payment-list">
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/visa.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/master-card.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/paypal.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/skrill.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/master-card2.jpg" alt="Method">
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <img src="assets/img/home-one/visa2.jpg" alt="Method">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>