<div class="one-header-top two-header-top">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3">
                <div class="header-social">
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="header-partner">
                    <p>We recognize this is a difficult time for many people. <a href="#">Become a Partner</a></p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="header-call">
                    <ul>
                        <li>
                            <span>Call Us:</span>
                            <a href="tel:+4234543123678">+4 234-543 123 678</a>
                        </li>
                        <li>
                            <span>Mail Us:</span>
                            <a href="mailto:info@levi.com">info@levi.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<nav class="navbar navbar-expand-lg navbar-light navbar-light-two sticky-top">
    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>

            <li class="nav-item"><a class="nav-link" href="#about">About Us</a></li>

            <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>

            <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>

            <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>

            <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
        </ul>
    </div>

    <div class="side-nav">
        <a class="side-nav-left" routerLink="/">Sign In</a>
        <a class="side-nav-right cd-signup" routerLink="/"><i class='bx bx-link-external'></i>Sign Up</a>
    </div>
</nav>

<div class="three-banner-area six-banner-area">
    <div class="banner-img">
        <img src="assets/img/home-six/banner-shape.png" alt="Banner">
    </div>

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
                    <span>
                        <img src="assets/img/home-three/banner-icon.png" alt="Banner">
                        Levi.com
                    </span>
                    <h1>Levi Residential <span>Mortgage</span></h1>
                    <ul>
                        <li>
                            <a class="cmn-banner-btn" routerLink="/home-six">Mortgage Expert <i class='bx bx-right-arrow-alt'></i></a>
                        </li>
                        <li>
                            <a class="banner-play popup-youtube" href="https://www.youtube.com/watch?v=aqz-KE-bpKQ"><i class='bx bx-play'></i></a>
                            <span>How It’s Work</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="five-quote-area six-quote-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="quote-content">
                    <div class="one-section-title three-section-title">
                        <span class="sub-title"># GET A QUOTE</span>
                        <h2>Get Mortgage Quote If You Have Any Doubt About It</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="quote-wrap">
                    <div class="quote-btn">
                        <a class="quote-btn-left" routerLink="/home-six"><i class='bx bxs-quote-right'></i> Get a quote</a>
                        <a class="quote-btn-right" routerLink="/home-six"><i class='bx bx-donate-heart'></i> Join community</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="three-loan-area five-loan-area six-loan-area ptb-100">
    <div class="container">
        <div class="five-loan-slider owl-theme owl-carousel">
            <div class="loan-item">
                <i class='bx bx-home'></i>
                <h3>A  Conventional Home Loan</h3>
                <p>Migration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-wrench'></i>
                <h3>Homeready Fannie Mortgage</h3>
                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-dollar-circle'></i>
                <h3>High Balance Mortgage</h3>
                <p>Wigration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-donate-heart'></i>
                <h3>Renovation Loans For Buyers</h3>
                <p>Migration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-home'></i>
                <h3>A  Conventional Home Loan</h3>
                <p>Migration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-wrench'></i>
                <h3>Homeready Fannie Mortgage</h3>
                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-dollar-circle'></i>
                <h3>High Balance Mortgage</h3>
                <p>Wigration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-donate-heart'></i>
                <h3>Renovation Loans For Buyers</h3>
                <p>Migration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>  
        </div>
    </div>
</section>
    
<div id="about" class="three-about-area four-about-area six-about-area pb-70">
    <div class="about-shape">
        <img src="assets/img/home-six/about4.png" alt="Shape">
    </div>

    <div class="container-fluid p-0">
        <div class="row align-items-center m-0">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title three-section-title">
                        <span class="sub-title"># ABOUT US</span>
                        <h2>We Are Fully Dedicated To Support You</h2>
                    </div>

                    <div class="about-mission">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-default active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true"><i class='bx bx-bullseye'></i> OUR MISSION</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-default" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false"><i class='bx bx-show-alt'></i> OUR VISION</a>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamquaerat.</p>
                                <ul>
                                    <li>
                                        <div class="about-support">
                                            <i class='bx bx-headphone'></i>
                                            <h3>Support Service</h3>
                                            <p>Your home equity by paying sit amet seeturlit sed dolor se equity </p>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="about-support">
                                            <i class='bx bx-bar-chart-alt'></i>
                                            <h3>Refinance Guide</h3>
                                            <p>Your home equity by paying sit amet seeturlit sed dolor se equity </p>
                                        </div>
                                    </li>
                                </ul>
                                <a class="cmn-banner-btn" routerLink="/home-six">Learn More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>

                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamquaerat.</p>
                                <ul>
                                    <li>
                                        <div class="about-support">
                                            <i class='bx bx-headphone'></i>
                                            <h3>Support Service</h3>
                                            <p>Your home equity by paying sit amet seeturlit sed dolor se equity </p>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="about-support">
                                            <i class='bx bx-bar-chart-alt'></i>
                                            <h3>Refinance Guide</h3>
                                            <p>Your home equity by paying sit amet seeturlit sed dolor se equity </p>
                                        </div>
                                    </li>
                                </ul>
                                <a class="cmn-banner-btn" routerLink="/home-six">Learn More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pr-0">
                <div class="about-img">
                    <img src="assets/img/home-six/about1.png" alt="About">
                    <img src="assets/img/home-six/about2.png" alt="About">
                    <img src="assets/img/home-six/about3.png" alt="About">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="one-contact-area three-contact-area six-contact-area">
    <div class="container">
        <div class="contact-content">
            <form id="contactForm">
                <div class="one-section-title three-section-title">
                    <span class="sub-title"># LET’S CONTACT</span>
                    <h2>Contact With Us</h2>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-user'></i></label>
                            <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-mail-send'></i></label>
                            <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-phone-call'></i></label>
                            <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>
                                <i class='bx bxs-edit-alt'></i>
                            </label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject">
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label><i class='bx bx-message-square-detail'></i></label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Message"></textarea>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <button type="submit" class="contact-btn btn">Send Message <i class='bx bx-right-arrow-alt'></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="six-counter-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <ul>
                        <li>
                            <div class="progress blue">
                                <span class="progress-left">
                                    <span class="progress-bar"></span>
                                </span>

                                <span class="progress-right">
                                    <span class="progress-bar"></span>
                                </span>

                                <div class="progress-value">
                                    <i class='bx bxs-bulb'></i>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="progress-content">
                                <h3>90</h3>
                                <p>Years Of Experience</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <ul>
                        <li>
                            <div class="progress yellow">
                                <span class="progress-left">
                                    <span class="progress-bar"></span>
                                </span>

                                <span class="progress-right">
                                    <span class="progress-bar"></span>
                                </span>

                                <div class="progress-value">
                                    <i class='bx bxs-home-smile'></i>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="progress-content">
                                <h3>69</h3>
                                <p>VETERAN HOMES COMPLETED</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <ul>
                        <li>
                            <div class="progress pink">
                                <span class="progress-left">
                                    <span class="progress-bar"></span>
                                </span>

                                <span class="progress-right">
                                    <span class="progress-bar"></span>
                                </span>

                                <div class="progress-value">
                                    <i class='bx bxs-user'></i>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="progress-content">
                                <h3>56</h3>
                                <p>VOLUNTEERS PARTICIPATED</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <ul>
                        <li>
                            <div class="progress pink">
                                <span class="progress-left">
                                    <span class="progress-bar"></span>
                                </span>

                                <span class="progress-right">
                                    <span class="progress-bar"></span>
                                </span>

                                <div class="progress-value">
                                    <i class='bx bx-world'></i>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="progress-content">
                                <h3>19</h3>
                                <p>CUSTOMER WORLDWIDE</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="services" class="four-step-area five-step-area six-step-area ptb-100">
    <div class="container-fluid">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># STEPS & PROCESS</span>
            <h2>Our Steps And Process How Works</h2>
            <p>Gnbyond ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
        </div>

        <div class="four-step-slider-two owl-theme owl-carousel">
            <div class="step-item step-one">
                <div class="step-span">
                    <span>01</span>
                </div>
                <h3>Conversation</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item">
                <div class="step-span">
                    <span>02</span>
                </div>
                <h3>Application</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item step-one">
                <div class="step-span">
                    <span>03</span>
                </div>
                <h3>Documents</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item">
                <div class="step-span">
                    <span>04</span>
                </div>
                <h3>Appraisal</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item step-one">
                <div class="step-span">
                    <span>05</span>
                </div>
                <h3>Upfront Underwriting</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item">
                <div class="step-span">
                    <span>04</span>
                </div>
                <h3>Appraisal</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>
        </div>
    </div>
</div>

<div class="six-nearly-area ptb-100">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># CHOOSE YOUR SERVICE</span>
            <h2>Nearly We Have Been Trying To Provide Those</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 pr-0">
                <div class="nearly-content">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <a class="nav-item nav-default active" id="nav-home-tab" data-bs-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Dealer Solutions</a>

                            <a class="nav-item nav-default" id="nav-profile-tab" data-bs-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Plan Financing</a>
                        </div>
                    </nav>

                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <h3>Auto Dealer Solutions</h3>
                            <p>Lorem ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
                            <ul>
                                <li><i class='bx bx-check'></i> Affordable Car Calculater</li>
                                <li><i class='bx bx-check'></i> Auto Financing Center</li>
                                <li><i class='bx bx-check'></i> Valley All Access Rewards</li>
                            </ul>
                            <div class="nearly-btn">
                                <a class="cmn-banner-btn" routerLink="/home-six">Get Quote <i class='bx bx-right-arrow-alt'></i></a>
                                <a class="nearly-btn" routerLink="/home-six">Learn More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <h3>Auto Dealer Solutions</h3>
                            <p>Lorem ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>
                            <ul>
                                <li><i class='bx bx-check'></i> Affordable Car Calculater</li>
                                <li><i class='bx bx-check'></i> Auto Financing Center</li>
                                <li><i class='bx bx-check'></i> Valley All Access Rewards</li>
                            </ul>
                            <div class="nearly-btn">
                                <a class="cmn-banner-btn" routerLink="/home-six">Get Quote <i class='bx bx-right-arrow-alt'></i></a>
                                <a class="nearly-btn" routerLink="/home-six">Learn More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pl-0">
                <div class="nearly-img">
                    <img src="assets/img/home-six/nearly-main.jpg" alt="Nearly">
                </div>
            </div>
        </div>
    </div>
</div>

<section id="team" class="four-team-area six-team-area ptb-100">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># TEAM & LEADERSHIP</span>
            <h2>Find A Loan Officer</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-six/team1.png" alt="Team">
                    <div class="team-bottom">
                        <h3>JAMES TAYLOR</h3>
                        <p>Branch Manager</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-six/team2.png" alt="Team">
                    <div class="team-bottom">
                        <h3>AMANDA SILBER</h3>
                        <p>Account Manager</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-six/team3.png" alt="Team">
                    <div class="team-bottom">
                        <h3>CHRIS GARCIA</h3>
                        <p>Loan Officer</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-six/team4.png" alt="Team">
                    <div class="team-bottom">
                        <h3>CHRIS M. SHELTON</h3>
                        <p>Regional Director</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="team-join">
            <p>Want To Be A Person! <a routerLink="/home-six">Join Now</a></p>
        </div>
    </div>
</section>

<section class="three-loan-area seven-loan-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># CATEGORIES OF LOAN</span>
            <h2>Find A Own Loan</h2>
        </div>

        <div class="sorting-menu">
            <ul> 
                <li class="filter active" data-filter="all"><i class='bx bx-cart'></i> PURCHASE</li>
                <li class="filter" data-filter=".tyre"><i class='bx bx-user'></i> HOMEBUYER</li>
                <li class="filter" data-filter=".web"><i class='bx bx-dollar' ></i> REFINANCE</li>
            </ul>
        </div>

        <div id="Container" class="row">
            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Home Loan</span>
                    <h3>A  Conventional Home Loan</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Homeready</span>
                    <h3>Homeready Fannie Mortgage</h3>
                    <p>Fyigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>High Balance</span>
                    <h3>High Balance Mortgage</h3>
                    <p>Wigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre web">
                <div class="loan-item">
                    <span>Renovation Loans</span>
                    <h3>Renovation Loans For Buyers</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>VA Loans</span>
                    <h3>VA Loans For Military</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Jumbo Loans</span>
                    <h3>When You Need Conforming Limit</h3>
                    <p>Fyigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Purchase Loans</span>
                    <h3>Down Payment Has Stalled </h3>
                    <p>Wigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Condo Loans</span>
                    <h3>Loans For Condo Support</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-six">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="two-banner-wrap three-banner-wrap four-banner-wrap">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 p-0">
                <div class="banner-time-left">
                    <ul>
                        <li>
                            <i class='bx bx-money'></i>
                            <span>What is price?</span>
                            <p>Mortgage Rates</p>
                        </li>

                        <li>
                            <i class='bx bx-calculator'></i>
                            <span>Get calculated?</span>
                            <a routerLink="/home-six">Calculators</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="banner-time-right">
                    <ul>
                        <li>
                            <i class='bx bx-dollar-circle'></i>
                            <span>What’s paying?</span>
                            <p>Payment By Credit Card</p>
                        </li>

                        <li>
                            <i class='bx bxs-contact'></i>
                            <span>Faq Or ask?</span>
                            <span>Get Quick Information</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="one-faq-area four-faq-area six-faq-area pt-100 pb-70">
    <div class="faq-shape">
        <img src="assets/img/home-six/faq4.png" alt="FAQ">
    </div>

    <div class="container-fluid p-0">
        <div class="row align-items-center m-0">
            <div class="col-lg-6 pl-0">
                <div class="faq-img">
                    <img src="assets/img/home-six/faq1.png" alt="Faq">
                    <img src="assets/img/home-six/faq2.png" alt="Faq">
                    <img src="assets/img/home-six/faq3.png" alt="Faq">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            <a>How do you qualify for a loan?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>A Good Lender Will Clearly Explain Mortgage?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>Can You Get A Mortgage Without A Credit Score?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>How Much Home Can You Afford?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="two-lover-area five-lover-area six-lover-area pb-100">
    <div class="container-fluid p-0">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># TESTIMONIAL</span>
            <h2>We Serve Our Clients Best Of Capacity</h2>
        </div>

        <div class="six-lover-slider owl-theme owl-carousel">
            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Donney Jon</h3>
                            <span>Perisian Org</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <ul>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star'></i></li>
                            </ul>
                            <div class="lover-icon">
                                <i class='bx bxs-quote-left'></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Oli Rubion</h3>
                            <span>Entoin Ect</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <ul>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star'></i></li>
                            </ul>
                            <div class="lover-icon">
                                <i class='bx bxs-quote-left'></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Sanaik Tubi</h3>
                            <span>Furnishhome, Internation</span>
                            <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <ul>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                            </ul>
                            <div class="lover-icon">
                                <i class='bx bxs-quote-left'></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Donney Jon</h3>
                            <span>Perisian Org</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <ul>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                            </ul>
                            <div class="lover-icon">
                                <i class='bx bxs-quote-left'></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Oli Rubion</h3>
                            <span>Entoin Ect</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <ul>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star'></i></li>
                            </ul>
                            <div class="lover-icon">
                                <i class='bx bxs-quote-left'></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="lover-item">
                <ul>
                    <li>
                        <div class="lover-content">
                            <h3>Sanaik Tubi</h3>
                            <span>Furnishhome, Internation</span>
                            <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <ul>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                                <li><i class='bx bxs-star checked'></i></li>
                            </ul>
                            <div class="lover-icon">
                                <i class='bx bxs-quote-left'></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="one-subscribe-area five-subscribe-area six-subscribe-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="subscribe-wrap">
                    <h2>Don't Miss Our Update Use This Subscription</h2>
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                        <button class="btn subscribe-btn" type="submit">Subscribe now</button>
                    </form>

                    <div class="sub-img">
                        <img src="assets/img/home-six/sub-main.png" alt="Subscribe">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="four-map-area">
    <div class="container-fluid p-0">
        <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59843174.53779284!2d62.17507173408573!3d23.728204508550373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1592754785579!5m2!1sen!2sbd"></iframe>
    </div>
</div>

<div class="six-hour-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="hour-item">
                    <i class='bx bxs-time'></i>
                    <div class="hour-inner">
                        <h3>Opening Time</h3>
                        <span>Mon-Fri (8AM - 6PM)</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="hour-item">
                    <i class='bx bxs-quote-alt-left'></i>
                    <div class="hour-inner">
                        <h3>Get A Quote</h3>
                        <a routerLink="/home-six">Find Free Quote?</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="hour-item">
                    <i class='bx bxs-phone-call'></i>
                    <div class="hour-inner">
                        <h3>Call Us:</h3>
                        <a href="tel:(435)-765-987">(435)-765-987</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="hour-item hour-right">
                    <i class='bx bx-message-detail'></i>
                    <div class="hour-inner">
                        <h3>Contact Us</h3>
                        <a href="mailto:info@levi.com">info@levi.com</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer id="contact" class="one-footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-two.png" alt="Logo"></a>
                        <p>Levi Financial Services, Inc. 200 Wood Avenue South, Ninth Floor Iselin, NJ 65432</p>
                        <p>Resouece Financial ServicInc. 200 Wood Avenue South</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/">Mortgage Calculator</a></li>
                            <li><a routerLink="/">Mortgage Checklist</a></li>
                            <li><a routerLink="/">Mortgage Glossary</a></li>
                            <li><a routerLink="/">Renting vs Owning</a></li>
                            <li><a routerLink="/">Credit Basics</a></li>
                            <li><a routerLink="/">Disaster Relief Center</a></li>
                            <li><a routerLink="/">Disclosure</a></li>
                            <li><a routerLink="/">Home Disclosure Notice</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>About Us</h3>

                        <ul>
                            <li><a routerLink="/">Down Payment Options</a></li>
                            <li><a routerLink="/">Your Mortgage Payment</a></li>
                            <li><a routerLink="/">Appraisal Basics</a></li>
                            <li><a routerLink="/">Contact</a></li>
                            <li><a routerLink="/">Careers</a></li>
                            <li><a routerLink="/">Privacy Policy</a></li>
                            <li><a routerLink="/">Privacy Choices</a></li>
                            <li><a routerLink="/">Licencing</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-address">
                        <h3>Address</h3>

                        <ul>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+4498886660000">+449 888 666 0000</a>
                            </li>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+5498586560350">+549 858 656 0350</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:hello@levi.com">hello@levi.com</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:info@levi.com">info@levi.com</a>
                            </li>
                            <li>
                                <i class='bx bx-current-location' ></i>
                                855 road, broklyn street new york 600
                            </li>
                            <li>
                                <i class='bx bx-planet' ></i>
                                Levi is not acting behalf the Federal Government
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <ul>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/paypal.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/skrill.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card2.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa2.jpg" alt="Method"></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>© Levi is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>