<div class="one-header-top">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-2">
                <div class="header-social">
                    <ul>
                        <li><a target="_blank" href="https://www.facebook.com/profile.php?id=100093388219227"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/maharicetraders/"><i
                                    class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="header-partner">
                    <p>We’re harvesting the grain of life, and our harvest is fruitful.
                    </p>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="header-call">
                    <ul>
                        <li>
                            <span>Call Us:</span>
                            <a href="tel:+91-9553737061">+91-9553737061</a>
                        </li>
                        <li>
                            <span>Mail Us:</span>
                            <a href="mailto:maharicetraders.hyd@gmail.com">maharicetraders.hyd@gmail.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<nav class="navbar navbar-expand-lg navbar-light sticky-top">
    <a class="navbar-brand" routerLink="/"><img src="assets/home/300-1.jpg" alt="Logo" width="160" height="80"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>
            <li class="nav-item"><a class="nav-link" href="#about">About Us</a></li>
            <li class="nav-item"><a class="nav-link" href="#services">Products</a></li>
            <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
        </ul>
    </div>
</nav>

<div class="one-banner-area">
    <div class="banner-shape">
        <img src="assets/img/home-one/banner-shape1.png" alt="Banner">
        <img src="assets/img/home-one/banner-shape2.png" alt="Banner">
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="banner-text">
                    <h1>Every grain is a tiny miracle.</h1>
                    <p>The rice blossom is the spirit of spring.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="banner-slider owl-theme owl-carousel">
                    <div class="banner-inner banner-img-one">
                        <img src="assets/img/home-one/banner-main-shape.png" alt="Banner">
                    </div>

                    <div class="banner-inner banner-img-two">
                        <img src="assets/img/home-one/banner-main-shape.png" alt="Banner">
                    </div>

                    <div class="banner-inner banner-img-three">
                        <img src="assets/img/home-one/banner-main-shape.png" alt="Banner">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="one-about-area">
    <div class="container-fluid p-0">
        <div class="row m-0" style="background: aliceblue;">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/home/about-1.jpg" alt="About">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title">
                        <span class="sub-title">ABOUT US</span>
                        <h4>A brand which is known for quality and commitment</h4>
                        <p>We have replaced plastic grains with natural grains, we are ripening fruits with chemicals,
                            even the poultry, fishery, and what not, everything on the planet is being disturbed by us
                            in the name of advancement.</p>
                        <p>As a farmer, I, can’t accept the advancement that kills the naturality of food and makes a
                            person's health imbalanced...</p>
                        <p>So, we work for 120 days to progress on the right cultivation for harvesting paddy to reach
                            needy.</p>
                        <ul>
                            <li>We follow the ancient methods of cultivation and harvesting</li>
                            <li>No chemicals used in cultivation and instead we use self prepared urea</li>
                            <li>No harmful oil polishing</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section id="services" class="one-service-area ptb-100">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">WHAT WE OFFER</span>
            <h2>Our Products</h2>
            <p>It is easier to give a cup of rice to relieve hunger than to relieve the loneliness and pain of someone
                unloved in our own home. Bring love into your home for this is where our love for each other must start.
            </p>
        </div>

        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist" style="justify-content: center;">
                <a class="nav-item nav-default active" id="nav-home-tab" data-bs-toggle="tab" href="#nav-home"
                    role="tab" aria-controls="nav-home" aria-selected="true">
                    <div class="service-item">
                        <span>Basmathi Rice</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-profile-tab" data-bs-toggle="tab" href="#nav-profile" role="tab"
                    aria-controls="nav-profile" aria-selected="false">
                    <div class="service-item">
                        <span>HMT Rice</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-contact-tab" data-bs-toggle="tab" href="#nav-contact" role="tab"
                    aria-controls="nav-contact" aria-selected="false">
                    <div class="service-item">
                        <span>Brown Rice</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-x-tab" data-bs-toggle="tab" href="#nav-x" role="tab"
                    aria-controls="nav-x" aria-selected="false">
                    <div class="service-item">
                        <span>Raw Rice</span>
                    </div>
                </a>
            </div>
        </nav>

        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/home/basmathirice1-1.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Basmathi Rice</h2>
                                <p>Basmati is gluten-free and low in fat. In addition to containing all eight essential
                                    amino acids and folic acid, it's very low in sodium and cholesterol-free.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/home/hmt-1.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>HMT Rice</h2>
                                <p>HMT Rice is healthy and flavourful staple that can be used for everyday consumption.
                                    It contains carbohydrates,contains vitamin B-complex, iron.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/home/brown-1.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Brown Rice</h2>
                                <p>Brown rice is rich in calcium and iron and helps in weight loss and Controls sugar
                                    level.Its nutritional values makes it much healthier and beneficial option.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-x" role="tabpanel" aria-labelledby="nav-x-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/home/raw-1.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Raw Rice</h2>
                                <p>Rice is a rich source of carbohydrates,the body's main fuel source. Carbohydrates can
                                    keep you energized and satisfied, and are important for exercise.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="contact" class="one-contact-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-5 p-0">
                <div class="contact-img">
                    <img src="assets/img/images/contact.avif" alt="Conatact">
                </div>
            </div>

            <div class="col-lg-7 p-0">
                <div class="contact-content">
                    <div class="one-section-title">
                        <span class="sub-title">LET’S CONTACT</span>
                        <h2>Contact With Us</h2>
                    </div>

                    <form id="contactForm" action="assets/js/mail.php">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label><i class='bx bx-user'></i></label>
                                    <input type="text" name="name" id="name" class="form-control" required
                                        placeholder="Name">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label><i class='bx bx-mail-send'></i></label>
                                    <input type="email" name="email" id="email" class="form-control" required
                                        placeholder="Email">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label><i class='bx bx-phone-call'></i></label>
                                    <input type="text" name="phone" id="phone_number" required class="form-control"
                                        placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label><i class='bx bxs-edit-alt'></i></label>
                                    <input type="text" name="subject" id="msg_subject" class="form-control" required
                                        placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label><i class='bx bx-message-square-detail'></i></label>
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                                        required placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="row m-0">
                                    <div class="col-sm-4 col-lg-4 pl-0">
                                        <button type="submit" class="contact-btn btn"><i class='bx bx-plus'></i> Send
                                            Message</button>
                                    </div>

                                    <div class="col-sm-8 col-lg-8">
                                        <div class="contact-emergency">
                                            <i class='bx bx-phone-call'></i>
                                            <span>Emergency Contact</span>
                                            <a href="tel:+44554348554">+91-9553737061</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="one-footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/home/301-1.png" alt="Logo" width="250" height="140"></a>
                        <p>The world’s best food comes from the hands of farmers who care as much about
                            their land as we do about ours.</p>
                        <ul>
                            <li><a href="https://www.facebook.com/profile.php?id=100093388219227" target="_blank"><i
                                        class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/maharicetraders/" target="_blank"><i
                                        class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-1"></div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>

                        <ul>
                            <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>

                            <li class="nav-item"><a class="nav-link" href="#about">About Us</a></li>

                            <li class="nav-item"><a class="nav-link" href="#services">Products</a></li>

                            <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-address">
                        <h3>Address</h3>

                        <ul>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+91-9553737061">+91-9553737061</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:maharicetraders.hyd@gmail.com">maharicetraders.hyd@gmail.com</a>
                            </li>
                            <li>
                                <i class='bx bx-current-location'></i>
                                EWS-529, Shop No. 1, Road No. 3, KPHB Colony, Kukatpally, Hyderabad-500072,
                                Telangana.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="copyright-item">
                        <p>@ Maha Rice Traders</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>