<nav class="navbar navbar-expand-lg navbar-light navbar-light-four sticky-top">
    <a class="navbar-brand" routerLink="/home-five"><img src="assets/img/logo-three.png" alt="Logo"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>

            <li class="nav-item"><a class="nav-link" href="#about">About Us</a></li>

            <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>

            <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>

            <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>

            <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
        </ul>
    </div>

    <div class="side-nav">
        <a class="side-nav-left" routerLink="/home-five">Sign In</a>
        <a class="side-nav-right" routerLink="/home-five"><i class='bx bx-link-external'></i>Sign Up</a>
    </div>
</nav>

<div class="five-banner-area">
    <div class="banner-shape">
        <img src="assets/img/home-five/banner1.png" alt="Shape">
        <img src="assets/img/home-five/banner-main.png" alt="Shape">
    </div>

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="banner-content">
                    <span>
                        <img src="assets/img/home-three/banner-icon.png" alt="Banner">
                        Welcome To Levi
                    </span>
                    <h1>Buy A Home By <span>Confidence</span></h1>
                    <p>Reliable Sustainable Mortgage Provider</p>

                    <div class="banner-form">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-default active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Purchase</a>
                            </li>

                            <li class="nav-item" role="presentation">
                                <a class="nav-default" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Refinance</a>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Purchase price *">
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Estimated monthly">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Down payment *">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Property ZIP code *">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <select class="form-control">
                                                    <option>Mortgage term length *</option>
                                                    <option>Another option</option>
                                                    <option>A option</option>
                                                    <option>Potato</option>
                                                </select>	
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Email address">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <button type="submit" class="btn banner-form-btn">Calculate money</button>
                                        </div>

                                        <div class="col-lg-6">
                                            <a class="banner-call" href="tel:5432123451">Call Us: 5432 123 451 (Sat - Thu)</a>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Purchase price *">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Estimated monthly">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Down payment *">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Property ZIP code *">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <select class="form-control">
                                                    <option>Mortgage term length *</option>
                                                    <option>Another option</option>
                                                    <option>A option</option>
                                                    <option>Potato</option>
                                                </select>	
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Email address">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <button type="submit" class="btn banner-form-btn">Calculate money</button>
                                        </div>

                                        <div class="col-lg-6">
                                            <a class="banner-call" href="tel:5432123451">Call Us: 5432 123 451 (Sat - Thu)</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="three-loan-area five-loan-area ptb-100">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># WHAT'S YOU EXPECT</span>
            <h2>We Have Offer To You</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed diusmod mlaboret dolore magnad minim veniamquaera</p>
        </div>

        <div class="five-loan-slider owl-theme owl-carousel">
            <div class="loan-item">
                <i class='bx bx-home'></i>
                <h3>A  Conventional Home Loan</h3>
                <p>Migration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-wrench'></i>
                <h3>Homeready Fannie Mortgage</h3>
                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-dollar-circle'></i>
                <h3>High Balance Mortgage</h3>
                <p>Wigration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-donate-heart'></i>
                <h3>Renovation Loans For Buyers</h3>
                <p>Migration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>

            <div class="loan-item">
                <i class='bx bx-home'></i>
                <h3>A  Conventional Home Loan</h3>
                <p>Migration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            
            <div class="loan-item">
                <i class='bx bx-wrench'></i>
                <h3>Homeready Fannie Mortgage</h3>
                <p>Fyigration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>

            <div class="loan-item">
                <i class='bx bx-dollar-circle'></i>
                <h3>High Balance Mortgage</h3>
                <p>Wigration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>

            <div class="loan-item">
                <i class='bx bx-donate-heart'></i>
                <h3>Renovation Loans For Buyers</h3>
                <p>Migration do amet contur diisci velit suia nonnua</p>
                <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>  
        </div>
    </div>
</section>

<div id="about" class="three-about-area five-about-area pb-70">
    <div class="about-shape">
        <img src="assets/img/home-five/about-shape2.png" alt="About">
    </div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/home-five/about-main.png" alt="About">
                    <img src="assets/img/home-five/about-shape.png" alt="About">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title three-section-title">
                        <span class="sub-title"># ABOUT US</span>
                        <h2>We Are Fully Dedicated To Support You</h2>
                    </div>

                    <div class="about-mission">
                        <ul class="nav nav-pills" id="pills-tab-two" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-default active" id="pills-home-tab-two" data-bs-toggle="pill" href="#pills-home-two" role="tab" aria-controls="pills-home" aria-selected="true"><i class='bx bx-bullseye'></i> OUR MISSION</a>
                            </li>

                            <li class="nav-item" role="presentation">
                                <a class="nav-default" id="pills-profile-tab-two" data-bs-toggle="pill" href="#pills-profile-two" role="tab" aria-controls="pills-profile" aria-selected="false"><i class='bx bx-show-alt'></i> OUR VISION</a>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent-two">
                            <div class="tab-pane fade show active" id="pills-home-two" role="tabpanel" aria-labelledby="pills-home-tab-two">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamquaerat.</p>
                                <ul>
                                    <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                </ul>
                                <a class="cmn-banner-btn" routerLink="/home-five">Learn More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>

                            <div class="tab-pane fade" id="pills-profile-two" role="tabpanel" aria-labelledby="pills-profile-tab-two">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamquaerat.</p>
                                <ul>
                                    <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                </ul>
                                <a class="cmn-banner-btn" routerLink="/home-five">Learn More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="five-quote-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-8 p-0">
                <div class="row quote-wrap align-items-center m-0">
                    <div class="col-lg-5">
                        <div class="quote-content">
                            <div class="one-section-title three-section-title">
                                <span class="sub-title"># GET A QUOTE</span>
                                <h2>Get Mortgage Quote If You have any Doubt It</h2>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7">
                        <div class="quote-btn">
                            <a class="quote-btn-left" routerLink="/home-five"><i class='bx bxs-quote-right'></i> Get a quote</a>
                            <a class="quote-btn-right" routerLink="/home-five"><i class='bx bx-donate-heart'></i> Join community</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="quote-img">
                    <img src="assets/img/home-five/quote.png" alt="Quote">
                </div>
            </div>
        </div>
    </div>
</div>

<section id="services" class="five-service-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># CHOOSE YOUR SERVICE</span>
            <h2>Nearly We Have Been Trying To Provide Those</h2>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="service-item">
                    <div class="service-img">
                        <img src="assets/img/home-five/service1.png" alt="Service">
                    </div>
                    <h3>Auto Dealer Solutions</h3>
                    <p>Lorem ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>

                    <ul>
                        <li>
                            <i class='bx bx-check'></i>
                            Affordable Car Calculator
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            Auto Financing Center
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            Valley All Access Rewards
                        </li>
                    </ul>

                    <a class="cmn-banner-btn" routerLink="/home-five">Get Quote <i class='bx bx-right-arrow-alt'></i></a>
                    <a class="service-btn" routerLink="/home-five">Learn More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="service-item">
                    <div class="service-img">
                        <img src="assets/img/home-five/service2.png" alt="Service">
                    </div>
                    <h3>Floor Plan Financing</h3>
                    <p>Ryun ipsum dolor sit ametconsecteturing elit sed do eiusmod tempor incididunt laboret dolore magna aliqua minaquaerat.</p>

                    <ul>
                        <li>
                            <i class='bx bx-check'></i>
                            Dealership to The Next Level
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            Financing Option For Your Dealership
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            Business Insight Center
                        </li>
                    </ul>

                    <a class="cmn-banner-btn" routerLink="/home-five">Get Quote <i class='bx bx-right-arrow-alt'></i></a>
                    <a class="service-btn" routerLink="/home-five">Learn More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="four-step-area five-step-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># STEPS & PROCESS</span>
            <h2>Go Beyond With Levi</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed diusmod temlaboret dolore magna aliqua. Ut enim ad minim veniamquaera</p>
        </div>

        <div class="four-step-slider owl-theme owl-carousel">
            <div class="step-item step-one">
                <div class="step-span">
                    <span>01</span>
                </div>
                <h3>Conversation</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item">
                <div class="step-span">
                    <span>02</span>
                </div>
                <h3>Application</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item step-one">
                <div class="step-span">
                    <span>03</span>
                </div>
                <h3>Documents</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item">
                <div class="step-span">
                    <span>04</span>
                </div>
                <h3>Appraisal</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item step-one">
                <div class="step-span">
                    <span>05</span>
                </div>
                <h3>Upfront Underwriting</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>

            <div class="step-item">
                <div class="step-span">
                    <span>04</span>
                </div>
                <h3>Appraisal</h3>
                <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
            </div>
        </div>
    </div>
</div>

<section id="team" class="four-team-area five-team-area ptb-100">
    <div class="team-shape">
        <img src="assets/img/home-five/team-bg.png" alt="Team">
    </div>

    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># TEAM & LEADERSHIP</span>
            <h2>Find A Loan Officer</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-four/team1.png" alt="Team">
                    <h3>JAMES TAYLOR</h3>
                    <p>Branch Manager</p>
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-four/team2.png" alt="Team">
                    <h3>AMANDA SILBER</h3>
                    <p>Account Manager</p>
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-four/team3.png" alt="Team">
                    <h3>CHRIS GARCIA</h3>
                    <p>Loan Officer</p>
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-four/team4.png" alt="Team">
                    <h3>CHRIS M. SHELTON</h3>
                    <p>Regional Director</p>
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="team-join">
            <p>Want To Be A Person! <a routerLink="/home-five">Join Now</a></p>
        </div>
    </div>
</section>

<div class="five-counter-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>90</h3>
                    <span>CUSTOMER WORLDWIDE</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item counter-two">
                    <h3>20</h3>
                    <span>VOLUNTEERS PARTICIPATED</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>56</h3>
                    <span>VETERAN HOME COMPLETED</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item counter-two">
                    <h3>65</h3>
                    <span>YEARS OF EXPERIENCE</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="one-faq-area four-faq-area five-faq-area pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            <a>How do you qualify for a loan?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>A Good Lender Will Clearly Explain Mortgage?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>Can You Get A Mortgage Without A Credit Score?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>How Much Home Can You Afford?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 pr-0">
                <div class="faq-img-two">
                    <img src="assets/img/home-five/faq.png" alt="FAQ">
                </div>
            </div>
        </div>
    </div>
</div>

<section class="three-loan-area four-loan-area five-loan-area pt-100">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># CATEGORIES OF LOAN</span>
            <h2>Find A Own Loan</h2>
        </div>

        <div class="sorting-menu">
            <ul> 
                <li class="filter active" data-filter="all"><i class='bx bx-cart'></i> PURCHASE</li>
                <li class="filter" data-filter=".tyre"><i class='bx bx-user'></i> HOMEBUYER</li>
                <li class="filter" data-filter=".web"><i class='bx bx-dollar' ></i> REFINANCE</li>
            </ul>
        </div>

        <div id="Container" class="row">
            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Home Loan</span>
                    <h3>A  Conventional Home Loan</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Homeready</span>
                    <h3>Homeready Fannie Mortgage</h3>
                    <p>Fyigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>High Balance</span>
                    <h3>High Balance Mortgage</h3>
                    <p>Wigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre web">
                <div class="loan-item">
                    <span>Renovation Loans</span>
                    <h3>Renovation Loans For Buyers</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>VA Loans</span>
                    <h3>VA Loans For Military</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Jumbo Loans</span>
                    <h3>When You Need Conforming Limit</h3>
                    <p>Fyigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Purchase Loans</span>
                    <h3>Down Payment Has Stalled </h3>
                    <p>Wigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Condo Loans</span>
                    <h3>Loans For Condo Support</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>
        </div>
    </div>
</section>

<section class="two-lover-area five-lover-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># TESTIMONIAL</span>
            <h2>We Serve Our Clients Best Of Capacity</h2>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="lover-item">
                    <ul>
                        <li>
                            <div class="lover-content">
                                <h3>Donney Jon</h3>
                                <span>Perisian Org</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                <ul>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                </ul>

                                <div class="lover-inner-img">
                                    <img src="assets/img/home-two/lover1.png" alt="Lover">
                                </div>

                                <div class="lover-icon">
                                    <i class='bx bxs-quote-left'></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="lover-item">
                    <ul>
                        <li>
                            <div class="lover-content">
                                <h3>Oli Rubion</h3>
                                <span>Entoin Ect</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                <ul>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star checked'></i></li>
                                    <li><i class='bx bxs-star'></i></li>
                                </ul>

                                <div class="lover-inner-img">
                                    <img src="assets/img/home-two/lover2.png" alt="Lover">
                                </div>

                                <div class="lover-icon">
                                    <i class='bx bxs-quote-left'></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="blog" class="one-blog-area five-blog-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># NEWS & BLOG</span>
            <h2>Featured News And Insights Update</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/home-four"><img src="assets/img/home-one/blog1.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>
                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                09 April 2023
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/home-four">What Means Your Mortgage And 5 Ways To Improve</a></h3>
                        <img src="assets/img/home-one/blog1.png" alt="Blog">
                        <p><a routerLink="/home-four">Zain jonson</a>, Author</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/home-four"><img src="assets/img/home-one/blog2.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>
                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                10 April 2023
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/home-four">8 Ways To Make Your Home A Smart Home</a></h3>
                        <img src="assets/img/home-one/blog2.png" alt="Blog">
                        <p><a routerLink="/home-four">Movin Jex</a>, Author</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="#"><img src="assets/img/home-one/blog3.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>
                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                11 April 2023
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/home-four">5 Ways To build Yourself By Hard Working</a></h3>
                        <img src="assets/img/home-one/blog3.png" alt="Blog">
                        <p><a routerLink="/home-four">Jems</a>, Author</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="one-subscribe-area five-subscribe-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="subscribe-wrap">
                    <h2>Do you want to get update what’s upcoming?</h2>
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                        <button class="btn subscribe-btn" type="submit">Subscribe now</button>
                    </form>

                    <div class="sub-img">
                        <img src="assets/img/home-five/subscribe-img.png" alt="Subscribe">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="four-map-area">
    <div class="container-fluid p-0">
        <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59843174.53779284!2d62.17507173408573!3d23.728204508550373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1592754785579!5m2!1sen!2sbd"></iframe>
    </div>
</div>

<footer id="contact" class="one-footer-area four-footer-area five-footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                        <p>Levi Financial Services, Inc. 200 Wood Avenue South, Ninth Floor Iselin, NJ 65432</p>
                        <p>Resouece Financial ServicInc. 200 Wood Avenue South</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/">Mortgage Calculator</a></li>
                            <li><a routerLink="/">Mortgage Checklist</a></li>
                            <li><a routerLink="/">Mortgage Glossary</a></li>
                            <li><a routerLink="/">Renting vs Owning</a></li>
                            <li><a routerLink="/">Credit Basics</a></li>
                            <li><a routerLink="/">Disaster Relief Center</a></li>
                            <li><a routerLink="/">Disclosure</a></li>
                            <li><a routerLink="/">Home Disclosure Notice</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>About Us</h3>

                        <ul>
                            <li><a routerLink="/">Down Payment Options</a></li>
                            <li><a routerLink="/">Your Mortgage Payment</a></li>
                            <li><a routerLink="/">Appraisal Basics</a></li>
                            <li><a routerLink="/">Contact</a></li>
                            <li><a routerLink="/">Careers</a></li>
                            <li><a routerLink="/">Privacy Policy</a></li>
                            <li><a routerLink="/">Privacy Choices</a></li>
                            <li><a routerLink="/">Licencing</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-address">
                        <h3>Send Message</h3>
                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Your name*">
                            </div>

                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Your email*">
                            </div>

                            <div class="form-group">
                                <textarea id="your_message" rows="4" class="form-control" placeholder="Your message"></textarea>
                            </div>

                            <button type="submit" class="footer-form-btn">SEND MESSAGE</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <ul>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/paypal.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/skrill.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card2.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa2.jpg" alt="Method"></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>© Levi is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>